import React, { Fragment } from "react";
import styled from "styled-components";
import { RadioLabel, RadioTitle, RadioContent } from "notes";

const Icon = styled.svg`
  width: 8px;
  height: 6px;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

export const StyledCheckbox = styled.div`
  align-items: center;
  box-sizing: border-box;
  background: ${(props) =>
    props.checked
      ? props.theme.typography.register.checkbox.checkedFill
      : props.theme.typography.register.checkbox.fill};
  border: 1px solid
    ${(props) =>
      props.checked
        ? props.theme.typography.register.checkbox.checkedBorder
        : props.theme.typography.register.checkbox.border};
  border-radius: ${(props) => props.theme.borders.inputBorderRadius ?? "2px"};
  display: inline-flex;
  flex-shrink: 0;
  height: 18px;
  justify-content: center;
  left: 0%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 150ms;
  width: 18px;

  input[disabled] + & {
    background: ${(props) =>
      props.checked
        ? props.theme.typography.register.checkbox.disabledCheckedFill
        : props.theme.typography.register.checkbox.disabledFill};
    border-color: ${(props) =>
      props.checked
        ? props.theme.typography.register.checkbox.disabledCheckedBorder
        : props.theme.typography.register.checkbox.disabledBorder};
  }

  ${Icon} {
    width: 12px;
    height: 12px;
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
    path {
      fill: ${(props) => props.theme.typography.register.input.color};
    }
  }
`;

const RadioContainer = styled(RadioLabel)`
  @media not screen and ${(props) => props.theme.media.large} {
    ${(props) => props.hideCheck && `padding: 0;`}
  }
`;

export const Checkbox = ({
  className,
  onChange,
  title,
  children,
  checked,
  disabled,
  hideCheck = false,
  ...props
}) => {
  const handleChange = (e) => {
    const {
      target: { checked },
    } = e;
    return onChange(checked, e);
  };
  return (
    <RadioContainer
      className={className}
      disabled={disabled}
      doubleLine={!!title}
      hideCheck={hideCheck}
    >
      <HiddenCheckbox
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
        {...props}
      />
      {!hideCheck && (
        <StyledCheckbox checked={checked} doubleLine={!!title}>
          <Icon viewBox="0 0 8 6">
            <path
              d="M6.798 0L8 1.192 3.15 6 0 2.877l1.202-1.192L3.15 3.617z"
              fill="#FFF"
            />
          </Icon>
        </StyledCheckbox>
      )}
      {!!title && <RadioTitle>{title}</RadioTitle>}
      <RadioContent doubleLine={!!title}>{children}</RadioContent>
    </RadioContainer>
  );
};
