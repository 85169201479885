import React from "react";
import styled from "styled-components";
import { P, GridRow, Icon } from "notes";

export const FieldMessage = ({
  content = "",
  type = "error",
  active = true,
  ...props
}) => {
  let color = type === "error" ? "red" : "green";
  return (
    active && (
      <ErrorMessageContainer yCenter {...props}>
        {type === "error" && <ErrorIcon form name="Error" />}
        {type === "success" && <SuccessIcon badge name="FacebookVerified" />}
        <Message color={color}>{content}</Message>
      </ErrorMessageContainer>
    )
  );
};

const ErrorMessageContainer = styled(GridRow)`
  margin-top: 11px;
`;

const Message = styled(P)`
  color: ${(props) => props.theme.typography.register.error.color};
  font-size: ${(props) => props.theme.typography.register.error.fontSize};
  font-weight: 400;
`;

const ErrorIcon = styled(Icon)`
  display: inline-flex;
  flex-shrink: 0;
  margin-right: 12px;
  position: relative;
  width: 16px;
  @media only screen and ${(props) => props.theme.media.small} {
    display: none;
  }
`;

const SuccessIcon = styled(ErrorIcon)`
  path {
    fill: ${(props) => props.theme.palette.green.primary};
  }
`;
