import React, { useState } from "react";
import styled from "styled-components";
import InputMask from "react-input-mask";
import { Input, FlexBox } from "notes";

const Container = styled(FlexBox)`
  position: relative;
`;

export const PhoneMask = ({
  phoneNumber: value,
  onChange,
  placeholder,
  ...props
}) => {
  const [focus, setFocus] = useState(false);
  return (
    <Container>
      <InputMask
        alwaysShowMask={false}
        maskChar=""
        mask="(999) 999-9999"
        value={value}
        onChange={(value) =>
          onChange(!!value.length ? value.replace(/[- )(]/g, "") : null)
        }
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      >
        {() => (
          <Input
            type="tel"
            data-javelin-name={`input-phone`}
            data-javelin-meta={JSON.stringify(value)}
            // leftIcon={<Icon form name="Phone" />}
            placeholder={focus ? "" : placeholder}
            maxLength={14}
            {...props}
          />
        )}
      </InputMask>
    </Container>
  );
};
