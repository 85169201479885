import React from "react";
import { Input, Icon } from "notes";
import { FieldMessage } from "Components";

export const ZipCode = ({ onChange, value, field, error = {}, ...props }) => {
  const handleZip = (value) => {
    if (value.match(/^[0-9\b]+$/) || value === "") {
      onChange({ [field.name]: value });
    }
  };
  return (
    <>
      <Input
        onChange={handleZip}
        value={value}
        placeholder={field.placeholder || "5-digit code..."}
        type="tel"
        error={!!error.status}
        maxLength={5}
        {...props}
      />
      <FieldMessage active={!!error.status} content={error.message} />
    </>
  );
};
