import React from "react";
import styled from "styled-components";
import { GridRow, GridColumn, P } from "notes";

export const Countdown = ({ label, days, hours, minutes, seconds }) => {
  return (
    <CountdownContainer xCenter>
      <CountdownTitle>{label}</CountdownTitle>
      <GridRow>
        {days >= 1 && (
          <Number xCenter>
            <Tick>{days}</Tick>
            <Label>Day{days !== 1 ? "s" : ""}</Label>
          </Number>
        )}
        <Number xCenter>
          <Tick>{hours}</Tick>
          <Label>Hour{hours !== 1 ? "s" : ""}</Label>
        </Number>
        <Number xCenter>
          <Tick>{minutes}</Tick>
          <Label>Minute{minutes !== 1 ? "s" : ""}</Label>
        </Number>
        {days < 1 && (
          <Number xCenter>
            <Tick>{seconds}</Tick>
            <Label>Second{seconds !== 1 ? "s" : ""}</Label>
          </Number>
        )}
      </GridRow>
    </CountdownContainer>
  );
};

const CountdownTitle = styled.h3`
  color: ${(props) => props.theme.typography.preShow.countdownLabel.color};
  ${(props) => props.theme.fonts.countdownLabel};
  font-size: ${(props) =>
    props.theme.typography.preShow.countdownLabel.fontSize[1]};
  font-weight: ${(props) =>
    props.theme.typography.preShow.countdownLabel.fontWeight};
  line-height: ${(props) =>
    props.theme.typography.preShow.countdownLabel.lineHeight[1]};
  margin-bottom: ${(props) =>
    props.theme.typography.preShow.countdownLabel.marginBottom[1]};
  margin-top: -8px;
  text-transform: ${(props) =>
    props.theme.typography.preShow.countdownLabel.textTransform};
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: ${(props) =>
      props.theme.typography.preShow.countdownLabel.fontSize[0]};
    line-height: ${(props) =>
      props.theme.typography.preShow.countdownLabel.lineHeight[0]};
    margin-bottom: ${(props) =>
      props.theme.typography.preShow.countdownLabel.marginBottom[0]};
    margin-top: -25px;
  }
  @media only screen and (max-width: 600px) {
    font-size: 6.833vw;
    line-height: 6.833vw;
  }
`;

const Tick = styled(P)`
  color: ${(props) => props.theme.typography.preShow.increment.color};
  ${(props) => props.theme.fonts.increment};
  font-weight: ${(props) =>
    props.theme.typography.preShow.increment.fontWeight};
  font-size: ${(props) => props.theme.typography.preShow.increment.fontSize[1]};
  line-height: ${(props) =>
    props.theme.typography.preShow.increment.lineHeight[1]};
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: ${(props) =>
      props.theme.typography.preShow.increment.fontSize[0]};
    line-height: ${(props) =>
      props.theme.typography.preShow.increment.lineHeight[0]};
  }
`;

const Label = styled.div`
  color: ${(props) => props.theme.typography.preShow.incrementLabel.color};
  ${(props) => props.theme.fonts.incrementLabel};
  font-weight: ${(props) =>
    props.theme.typography.preShow.incrementLabel.fontWeight};
  font-size: ${(props) =>
    props.theme.typography.preShow.incrementLabel.fontSize[1]};
  line-height: ${(props) =>
    props.theme.typography.preShow.incrementLabel.lineHeight[1]};
  text-transform: uppercase;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: ${(props) =>
      props.theme.typography.preShow.incrementLabel.fontSize[0]};
    line-height: ${(props) =>
      props.theme.typography.preShow.incrementLabel.lineHeight[0]};
  }
`;

const CountdownContainer = styled(GridColumn)`
  background-color: ${(props) => props.theme.layout.preShow.videoFill};
  justify-content: center;
  height: 100%;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    justify-content: center;
  }
`;

const Number = styled(GridColumn)`
  border: 4px solid ${(props) => props.theme.layout.preShow.borderColor};
  border-radius: 15px;
  height: 288px;
  justify-content: space-between;
  padding: 38px 0;
  width: 236px;
  & + & {
    margin-left: 24px;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    border-radius: 15px;
    height: 109px;
    padding: 14px 0;
    width: 89px;
    & + & {
      margin-left: 9px;
    }
  }
`;
