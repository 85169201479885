import React from "react";
import styled from "styled-components";
import { GridColumn, H3 } from "notes";
import ReactHtmlParser from "react-html-parser";
import { Video } from "Components";
import { Layout, BodySection } from "../Layout";

export const Preview = ({ event = {} }) => {
  const {
    heading,
    subHeading,
    overlayHeading,
    body,
    videoId,
    artistImage,
  } = event;
  return (
    <Layout
      heading={heading}
      subHeading={subHeading}
      videoContent={
        videoId ? (
          <Video id={videoId} />
        ) : (
          artistImage && (
            <ArtistImage background={artistImage} xCenter yEnd>
              {overlayHeading && (
                <OverlayHeading>
                  {ReactHtmlParser(overlayHeading)}
                </OverlayHeading>
              )}
            </ArtistImage>
          )
        )
      }
    >
      <BodySection>{ReactHtmlParser(body)}</BodySection>
    </Layout>
  );
};

const OverlayHeading = styled(H3)`
  color: ${(props) => props.theme.typography.preview.overlayHeading.color};
  font-size: ${(props) =>
    props.theme.typography.preview.overlayHeading.fontSize[1]};
  font-weight: ${(props) =>
    props.theme.typography.preview.overlayHeading.fontWeight};
  line-height: ${(props) =>
    props.theme.typography.preview.overlayHeading.lineHeight[1]};
  margin: ${(props) => props.theme.typography.preview.overlayHeading.margin[1]};
  text-align: ${(props) =>
    props.theme.typography.preview.overlayHeading.textAlign[1]};
  text-transform: uppercase;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: ${(props) =>
      props.theme.typography.preview.overlayHeading.fontSize[0]};
    line-height: ${(props) =>
      props.theme.typography.preview.overlayHeading.lineHeight[0]};
    margin: ${(props) =>
      props.theme.typography.preview.overlayHeading.margin[0]};
    text-align: ${(props) =>
      props.theme.typography.preview.overlayHeading.textAlign[1]};
  }
`;

const ArtistImage = styled(GridColumn)`
  background-image: url(${(props) => props.background});
  background-position: ${(props) =>
    props.theme.layout.preview.artistImage.backgroundPosition};
  background-size: ${(props) =>
    props.theme.layout.preview.artistImage.backgroundSize};
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  z-index: 1;
`;
