export const theme = {
  borders: {
    input: "none",
    inputBorderRadius: "4px",
  },
  // carousel: {
  //   autoplay: true,
  //   autoplaySpeed: 10000,
  //   backgroundColor: "#ffffff",
  //   padding: ["40px 0"],
  // },
  colors: {
    // Layout colors
    wrapperFill: "#293338",
    headerFill: "#ffffff",
    videoFill: "#1e1e1e",
    headingFill: "#0731A1",
    bodyFill: "#ffffff",
    footerFill: "#0731A1",

    // Typography
    linkText: "#636363",

    // Register page
    contentFill: "#0731A1",
    imageBackgroundFill: "#000000",

    // Fields
    inputBg: "#ffffff",
    inputText: "#222222",
    inputPlaceholderText: "#596266",
    selectItemDivider: "#E6E9EB",

    // Carousel
    carouselFill: "#ffffff",
    carouselItemFill: "#eeeeee",
  },
  fonts: {
    header: "font-family: 'stag-sans-black', sans-serif;",
    default: "font-family: 'stag-sans-light', sans-serif;",
    body: "font-family: 'stag-sans-light', sans-serif;",
    countdownLabel: "font-family: 'stag-sans-medium', sans-serif;",
    incrementLabel: "font-family: 'Work Sans', sans-serif;",
    increment: "font-family: 'Roboto', sans-serif;",
    button: "font-family: 'stag-sans-light', sans-serif;",
  },
  layout: {
    wrapper: { maxWidth: ["100%", "1400px"] },
    header: {
      alignContent: ["center", "flex-start"],
      height: ["inherit", "inherit"],
      padding: ["10px 24px", "16px 40px"],
      logo: {
        margin: [0, 0],
        width: ["220px", "381px"],
      },
    },
    heading: {
      padding: ["20px 24px", "20px 24px"],
    },
    body: {
      padding: ["24px", "45px 40px 55px 40px"],
      alignContent: ["center", "center"],
    },
    footer: {
      alignContent: ["center", "center"],
      padding: ["30px 32px", "80px 32px"],
      logo: {
        width: ["300px", "348px"],
      },
    },

    // Page specific
    register: {
      content: {
        align: ["left", "left"],
        maxWidth: ["500px", "100%"],
        padding: ["24px 24px 40px 24px", "32px 40px 40px 40px"],
      },
      artistImage: {
        width: ["100%", "54%"],
        imageWidth: 756,
        imageHeight: 928,
      },
      altLogo: {
        active: false,
      },
      overlayImage: {
        active: false,
        width: "260px",
      },
    },
    preview: {
      artistImage: {
        backgroundPosition: "center center",
        backgroundSize: "cover",
      },
    },
    preShow: {
      videoFill: "#1e1e1e",
      videoPadding: "56%",
      borderColor: "#ffffff",
    },
    postShow: {
      videoFill: "#ffffff",
      videoPadding: "50%",
      // overlayImage: {
      //   marginTop: ["24px", "65px"],
      //   width: ["180px", "260px"],
      // },
    },
  },
  media: {
    small: "(max-width: 950px)",
    medium: "(min-width: 951px)",
    large: "(min-width: 1240px)",
  },
  typography: {
    heading: {
      color: "#ffffff",
      fontSize: ["32px", "55px"],
      lineHeight: ["32px", "55px"],
      fontWeight: 300,
      textAlign: ["center", "center"],
      textTransform: "none",
    },
    subHeading: {
      color: "#000000",
      fontSize: ["18px", "32px"],
      lineHeight: ["22px", "36px"],
      fontWeight: 700,
      textTransform: "none",
      textAlign: ["center", "center"],
      margin: ["0 0 25px 0", "0 0 45px 0"],
    },
    body: {
      color: "#1F232E",
      fontSize: ["25px", "25px"],
      lineHeight: ["30px", "30px"],
      fontWeight: 300,
    },
    // Page Specific
    register: {
      heading: {
        color: "#ffffff",
        fontSize: ["46px", "60px"],
        lineHeight: ["50px", "60px"],
        fontWeight: 700,
        maxWidth: ["100%", "100%"],
        marginBottom: ["24px", "42px"],
        textTransform: "none",
        order: 1,
      },
      subHeading: {
        color: "#ffffff",
        fontSize: ["14px", "18px"],
        lineHeight: ["18px", "22px"],
        fontWeight: 700,
        margin: ["0 0 10px 0", "0 0 10px 0"],
        textTransform: "none",
        order: 1,
      },
      body: {
        color: "#ffffff",
        font: "font-family: 'stag-sans-medium', sans-serif;",
        fontSize: ["22px", "20px"],
        lineHeight: ["26px", "24px"],
        fontWeight: 300,
        marginBottom: ["24px", "24px"],
      },
      formHeading: {
        color: "#ffffff",
        font: "font-family: 'stag-sans-medium', sans-serif;",
        fontSize: ["18px", "20px"],
        lineHeight: ["22px", "24px"],
        fontWeight: 500,
        marginBottom: ["16px", "16px"],
        textTransform: "none",
      },
      input: {
        color: "#636363",
        fontSize: ["15px", "15px"],
        lineHeight: ["19px", "19px"],
        fontWeight: 400,
        verticalSpacing: [8, 8],
        horizontalSpacing: [6, 6],
      },
      checkbox: {
        fill: "#ffffff",
        border: "#ffffff",
        checkedFill: "#ffffff",
        checkedBorder: "#ffffff",
        borderError: "2px solid #E45C52",
        disabledFill: "#dcdcdc",
        disabledBorder: "#dcdcdc",
        disabledCheckedFill: "#dcdcdc",
        disabledCheckedBorder: "#dcdcdc",
      },
      checkboxLabel: {
        color: "#ffffff",
        fontSize: ["15px", "15px"],
        lineHeight: ["19px", "19px"],
        fontWeight: 300,
      },
      button: {
        color: "#ffffff",
        fill: "#0070F0",
        border: "none",
        borderRadius: 0,
        fontSize: ["24px", "24px"],
        lineHeight: ["26px", "26px"],
        fontWeight: 700,
        height: ["60px", "84px"],
        textTransform: "none",
        margin: ["8px 0 0 0", "8px 0 0 0"],
        colorHover: "#ffffff",
        borderHover: "none",
        fillHover: "#0065A4",
      },
      error: { color: "#ffffff", fontSize: "12px" },
    },
    preview: {
      overlayHeading: {
        color: "#ffffff",
        fontSize: ["20px", "48px"],
        lineHeight: ["22px", "54px"],
        fontWeight: 700,
        margin: ["0 0 60px 0", "0 0 16px 0"],
        textAlign: ["center", "center"],
      },
    },
    preShow: {
      countdownLabel: {
        color: "#0065A4",
        fontSize: ["38px", "70px"],
        fontWeight: 500,
        lineHeight: ["42px", "74px"],
        marginBottom: ["10px", "40px"],
        textTransform: "none",
      },
      increment: {
        color: "#ffffff",
        fontSize: ["46px", "124px"],
        lineHeight: ["50px", "140px"],
        fontWeight: 900,
      },
      incrementLabel: {
        color: "#ffffff",
        fontWeight: 700,
        fontSize: ["9px", "25px"],
        lineHeight: ["12px", "32px"],
      },
    },
    postShow: {
      overlayHeading: {
        color: "#0065A4",
        fontWeight: 300,
        fontSize: ["42px", "120px"],
        lineHeight: ["42px", "120px"],
        marginBottom: ["24px", "24px"],
        textTransform: "none",
      },
      overlayBody: {
        color: "#6B6B6B",
        fontWeight: 700,
        fontSize: ["18px", "36px"],
        lineHeight: ["22px", "40px"],
      },
    },
    reminder: {
      heading: {
        color: ["#1e1e1e", "#ffffff"],
        fontSize: ["32px", "46px"],
        lineHeight: ["36px", "50px"],
        textTransform: "uppercase",
      },
      button: {
        color: "#ffffff",
        colorHover: "#ffffff",
        fill: "#314047",
        fillHover: "#293338",
      },
    },
  },
};
