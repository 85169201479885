import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  GridColumn,
  H3,
  P,
  ButtonPrimary,
  Footnote,
  Label,
  LoaderInline,
  H2,
} from "notes";
import {
  PhoneMask,
  firebase,
  MessageContext,
  FieldMessage,
  EventContext,
} from "Components";
import { Layout } from "../Root/Components/Layout";

export const Reminder = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const defaultNumber = urlParams.get("phone");
  const userToken = urlParams.get("token");

  const [phoneValue, setPhoneValue] = useState(defaultNumber);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { displayMessage } = useContext(MessageContext);
  const { event } = useContext(EventContext);

  const history = useHistory();

  const handleSubmit = async () => {
    if (!!phoneValue && phoneValue.length >= 10) {
      setLoading(true);
      try {
        const authRemind = firebase.functions().httpsCallable("auth-remind");
        authRemind({
          token: userToken,
          phone: phoneValue,
          eventId: process.env.REACT_APP_EVENT_ID,
        }).then(({ data }) => {
          if (data.success) {
            history.push(
              `/?token=${encodeURIComponent(decodeURIComponent(userToken))}`
            );
            displayMessage({
              color: "green",
              children:
                "Your reminder and link will be sent on the day of the show!",
              timeout: 20000,
            });
          } else {
            setLoading(false);
            setError(
              "There was an issue with your request. Please try clicking the link from your email again."
            );
          }
        });
      } catch (error) {
        setLoading(false);
        setError(error.message);
      }
    } else {
      setError("You must enter a valid phone number to receive a reminder.");
    }
  };

  return (
    <LayoutStyle register>
      <Container xCenter yCenter>
        {!!event?.reminder && <Heading>{event?.reminder?.heading}</Heading>}
        <Card>
          <H3>Text Message Reminder</H3>
          <P>
            Text me a reminder 30 minutes before the show with my ticket link
          </P>
          <Label>Mobile Phone Number</Label>
          <PhoneMask
            phoneNumber={phoneValue}
            onChange={(value) => {
              setPhoneValue(value);
              setError("");
            }}
            error={!!error}
            placeholder="Phone Number"
          />
          <FieldMessage active={!!error} content={error} />
          <Footnote>
            Message and data rates may apply. Consent is not a condition of
            purchase. Text back STOP to cancel
          </Footnote>
          <Button
            disabled={loading}
            onClick={handleSubmit}
            style={{ marginTop: "40px" }}
          >
            {loading ? <LoaderInline /> : "Text me a reminder!"}
          </Button>
        </Card>
      </Container>
    </LayoutStyle>
  );
};

const Button = styled(ButtonPrimary)`
  color: ${(props) => props.theme.typography.reminder.button.color};
  margin-top: 40px;
  background-color: ${(props) => props.theme.typography.reminder.button.fill};

  &:hover,
  &:focus {
    color: ${(props) => props.theme.typography.reminder.button.colorHover};
    background-color: ${(props) =>
      props.theme.typography.reminder.button.fillHover};
  }
`;

const LayoutStyle = styled(Layout)`
  flex-grow: 1;
  text-align: left;
  @media only screen and ${(props) => props.theme.media.small} {
    background-color: #ffffff;
  }
`;

const Container = styled(GridColumn)`
  height: 100%;
  flex-grow: 1;
  width: 100%;
  padding-top: 40px;
`;

const Heading = styled(H2)`
  color: ${(props) => props.theme.typography.reminder.heading.color[1]};
  font-size: ${(props) => props.theme.typography.reminder.heading.fontSize[1]};
  line-height: ${(props) =>
    props.theme.typography.reminder.heading.lineHeight[1]};
  max-width: 450px;
  text-transform: ${(props) =>
    props.theme.typography.reminder.heading.textTransform};
  @media only screen and ${(props) => props.theme.media.small} {
    color: ${(props) => props.theme.typography.reminder.heading.color[0]};
    font-size: ${(props) =>
      props.theme.typography.reminder.heading.fontSize[0]};
    line-height: ${(props) =>
      props.theme.typography.reminder.heading.lineHeight[0]};
    max-width: 300px;
    text-align: center;
  }
`;

const Card = styled(GridColumn)`
  background-color: #ffffff;
  border: 1px solid ${(props) => props.theme.palette.gray.lighter};
  border-radius: 8px;
  color: #222222;
  margin: 24px 0 72px 0;
  padding: 40px;
  position: relative;
  width: 100%;
  max-width: 472px;
  ${H3} {
    line-height: 32px;
    margin-bottom: 4px;
  }
  ${H3} + ${P} {
    margin-bottom: 24px;
  }

  ${Label} {
    margin-bottom: 4px;
  }

  ${Footnote} {
    margin-top: 8px;
  }

  @media only screen and ${(props) => props.theme.media.small} {
    border: none;
    flex-grow: 1;
    margin: 0;
    padding: 40px 24px 104px 24px;

    ${H3} {
      font-size: 17px;
      height: auto;
      line-height: 22px;
    }
    ${H3} + ${P} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;
