import { useContext, useEffect } from "react";
import { TimeContext, firebase } from "Components";
import { fireAnalytics } from "services";

export const Heartbeater = ({ token }) => {
  const { time } = useContext(TimeContext);
  useEffect(() => {
    if (time.second === 0) {
      fireAnalytics("heartbeat_1min", {
        event_category: "heartbeats",
      });
      if (token) {
        const access = firebase.functions().httpsCallable("auth-access");
        access({
          token: token,
          eventId: process.env.REACT_APP_EVENT_ID,
        }).catch((err) => console.error("error", err));
      }
    }
  }, [time]);

  return null;
};
