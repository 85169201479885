import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { GridColumn, GridRow, H3 } from "notes";

export const Layout = ({
  children,
  heading,
  subHeading,
  videoContent,
  videoPadding = "56.24%",
  noFooter,
  noHeader,
  noPadding,
  headerStyle,
  videoStyle,
  ...props
}) => {
  const themeContext = useContext(ThemeContext);
  return (
    <Wrapper stretch>
      {!noHeader && (
        <Header style={headerStyle}>
          <Logo src={themeContext.images?.logo?.header} />
        </Header>
      )}
      {videoContent && (
        <VideoContainer paddingTop={videoPadding}>
          <VideoInner style={videoStyle}>{videoContent}</VideoInner>
        </VideoContainer>
      )}
      {heading && (
        <VideoBanner>
          <Title>{heading}</Title>
        </VideoBanner>
      )}
      {!!children && (
        <Body noPadding={noPadding} {...props} stretch>
          {subHeading && <Subheading>{ReactHtmlParser(subHeading)}</Subheading>}
          {children}
        </Body>
      )}
      {!noFooter && (
        <Footer>
          <FooterWidth>
            {themeContext?.images?.logo?.footer && (
              <FooterLogo src={themeContext.images?.logo?.footer} />
            )}
          </FooterWidth>
        </Footer>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(GridColumn)`
  background: #fff;
  box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.65);
  margin: 0 auto;
  position: relative;
  width: ${(props) => props.theme.layout.wrapper.maxWidth[0]};
  @media only screen and ${(props) => props.theme.media.small} {
    align-items: center;
    box-shadow: none;
  }
  @media only screen and ${(props) => props.theme.media.medium} {
    align-items: center;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    max-width: ${(props) => props.theme.layout.wrapper.maxWidth[1]};
    width: 100%;
  }
`;

const Header = styled(GridRow)`
  align-items: center;
  background-color: ${(props) => props.theme.colors.headerFill};
  height: ${(props) => props.theme.layout.header.height[1]};
  padding: ${(props) => props.theme.layout.header.padding[1]};
  position: relative;
  justify-content: ${(props) => props.theme.layout.header.alignContent[1]};
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    justify-content: ${(props) => props.theme.layout.header.alignContent[0]};
    height: ${(props) => props.theme.layout.header.height[0]};
    padding: ${(props) => props.theme.layout.header.padding[0]};
  }
`;

const Logo = styled.img`
  margin: 0;
  width: 100%;
  margin: ${(props) => props.theme.layout.header.logo.margin[1]};
  max-width: ${(props) => props.theme.layout.header.logo.width[1]};
  z-index: 2;
  @media only screen and ${(props) => props.theme.media.small} {
    width: 100%;
    margin: ${(props) => props.theme.layout.header.logo.margin[0]};
    max-width: ${(props) => props.theme.layout.header.logo.width[0]};
  }
`;

const VideoContainer = styled.div`
  background-color: ${(props) => props.theme.colors.videoFill};
  display: flex;
  padding-top: ${(props) => props.paddingTop};
  position: relative;
  width: 100%;
`;

const VideoInner = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0%;
  right: 0px;
  bottom: 0;
  left: 0px;
  .vimeo {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
`;

const VideoBanner = styled(GridRow)`
  background-color: ${(props) => props.theme.colors.headingFill};
  padding: ${(props) => props.theme.layout.heading.padding[1]};
  width: 100%;
  @media only screen and (max-width: 600px) {
    padding: ${(props) => props.theme.layout.heading.padding[0]};
  }
`;

const Title = styled.h3`
  color: ${(props) => props.theme.typography.heading.color};
  ${(props) => props.theme.fonts.header};
  font-size: ${(props) => props.theme.typography.heading.fontSize[1]};
  line-height: ${(props) => props.theme.typography.heading.lineHeight[1]};
  font-weight: ${(props) => props.theme.typography.heading.fontWeight[1]};
  position: relative;
  text-transform: ${(props) => props.theme.typography.heading.textTransform};
  z-index: 5;
  width: 100%;
  text-align: ${(props) => props.theme.typography.heading.textAlign[1]};
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: ${(props) => props.theme.typography.heading.fontSize[0]};
    line-height: ${(props) => props.theme.typography.heading.lineHeight[0]};
    text-align: ${(props) => props.theme.typography.heading.textAlign[0]};
  }
`;

const Body = styled(GridColumn)`
  background-color: ${(props) => props.theme.colors.bodyFill};
  color: ${(props) => props.theme.typography.body.color};
  padding: ${(props) =>
    props.noPadding ? 0 : props.theme.layout.body.padding[1]};
  width: 100%;
  @media only screen and (max-width: 600px) {
    padding: ${(props) =>
      props.noPadding ? 0 : props.theme.layout.body.padding[0]};
  }
`;

const Footer = styled(GridRow)`
  background-color: ${(props) => props.theme.colors.footerFill};
  padding: ${(props) => props.theme.layout.footer.padding[1]};
  justify-content: ${(props) => props.theme.layout.footer.alignContent[1]};
  width: 100%;
  @media only screen and (max-width: 600px) {
    justify-content: ${(props) => props.theme.layout.footer.alignContent[0]};
    padding: ${(props) => props.theme.layout.footer.padding[0]};
  }
`;

const FooterWidth = styled(GridRow)`
  width: 100%;
  max-width: ${(props) => props.theme.layout.footer.logo.width[1]};
  @media only screen and (max-width: 600px) {
    max-width: ${(props) => props.theme.layout.footer.logo.width[0]};
  }
`;

const FooterLogo = styled.img`
  height: 100%;
  width: 100%;
  max-width: 540px;
`;

const Subheading = styled(H3)`
  color: ${(props) => props.theme.typography.subHeading.color};
  ${(props) => props.theme.fonts.header};
  font-size: ${(props) => props.theme.typography.subHeading.fontSize[1]};
  font-weight: ${(props) => props.theme.typography.subHeading.fontWeight};
  line-height: ${(props) => props.theme.typography.subHeading.lineHeight[1]};
  margin: ${(props) => props.theme.typography.subHeading.margin[1]};
  text-align: ${(props) => props.theme.typography.subHeading.textAlign[1]};
  text-transform: ${(props) => props.theme.typography.subHeading.textTransform};
  width: 100%;
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: ${(props) => props.theme.typography.subHeading.fontSize[0]};
    line-height: ${(props) => props.theme.typography.subHeading.lineHeight[0]};
    margin: ${(props) => props.theme.typography.subHeading.margin[0]};
    text-align: ${(props) => props.theme.typography.subHeading.textAlign[0]};
  }
`;

export const BodySection = styled(GridColumn)`
  ${(props) => props.theme.fonts.body};
  font-size: 20px;
  line-height: 24px;
  justify-content: center;
  text-align: ${(props) => props.theme.layout.body.alignContent[1]};
  @media only screen and (max-width: 600px) {
    font-size: 15px;
    line-height: 22px;
    justify-content: flex-start;
    text-align: ${(props) => props.theme.layout.body.alignContent[0]};
  }
`;
