import React from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { FieldMessage, Checkbox } from "Components";

export const OptIn = ({
  onChange,
  value,
  field,
  error = {},
  fieldCheck,
  ...props
}) => {
  const handleChange = (value) => {
    onChange({ [field.name]: value });
  };
  return (
    <>
      <Checkbox
        title={<Formatting>{ReactHtmlParser(field.label)}</Formatting>}
        checked={value}
        error={!!error.status}
        onChange={handleChange}
        disabled={!fieldCheck}
      />
      <FieldMessage active={!!error.status} content={error.message} />
    </>
  );
};

const Formatting = styled.div`
  color: ${(props) => props.theme.typography.register.checkboxLabel.color};
  font-size: ${(props) =>
    props.theme.typography.register.checkboxLabel.fontSize[1]};
  font-weight: ${(props) =>
    props.theme.typography.register.checkboxLabel.fontWeight};
  line-height: ${(props) =>
    props.theme.typography.register.checkboxLabel.lineHeight[1]};
  a,
  a:hover {
    color: ${(props) => props.theme.colors.linkText};
    font-weight: 600;
    text-decoration: underline;
  }
  small {
    font-size: 80%;
  }

  @media only screen and ${(props) => props.theme.media.small} {
    font-size: ${(props) =>
      props.theme.typography.register.checkboxLabel.fontSize[0]};
    line-height: ${(props) =>
      props.theme.typography.register.checkboxLabel.lineHeight[0]};
  }
`;
