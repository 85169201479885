import React, { useState, useReducer, useContext } from "react";
import ReactHtmlParser from "react-html-parser";
import styled, { ThemeContext } from "styled-components";
import { GridColumn, Label, ButtonPrimary, H2, H3, P, GridRow } from "notes";
import { Field, handleErrors, firebase, FormMessage } from "Components";
import { reducer } from "Modules";
import { Layout } from "../Layout";
import { fireAnalytics } from "services";

const initRegistration = {
  FNAME: "",
  LNAME: "",
  EMAIL: "",
  PHONE: "",
  ZIP: "",
  OPTIN: false,
  OPTINPHONE: true,
};

export const Register = React.memo(({ event = {}, onSuccess }) => {
  const {
    artist,
    title,
    body,
    buttonLabel,
    fields,
    formHeading,
    formId,
    heading,
    subHeading,
    mastercampaignId,
  } = event;
  const themeContext = useContext(ThemeContext);

  const [registration, setRegistration] = useReducer(reducer, initRegistration);
  const [errors, setErrors] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (value, name) => {
    setRegistration(value);
    delete errors[name];
    setErrors(errors);
  };

  const handleSubmit = async () => {
    let errorObject = handleErrors(fields, registration);

    if (!errorObject) {
      setSubmitting(true);
      try {
        const submitForm = firebase.functions().httpsCallable("leads-submit");
        await submitForm({
          form: {
            ...registration,
          },
          email: registration.EMAIL,
          form_id: formId,
          mcId: mastercampaignId,
        }).then(({ data }) => {
          if (!!data.token) {
            onSuccess(false, data.token);
            setSubmitting(false);
            fireAnalytics("form_submit", {
              event_category: artist,
              event_label: title,
              formId: formId,
              setLiveEventId: process.env.REACT_APP_EVENT_ID,
            });
          }
        });
      } catch (error) {
        setSubmitting(false);
      }
    } else {
      setErrors(errorObject);
    }
  };

  return (
    <Layout noFooter noPadding>
      <ColumnContainer>
        <Content>
          <Container>
            {themeContext.layout.register.altLogo?.active && (
              <Logo src={themeContext.images?.logo?.header} />
            )}
            <Heading>{ReactHtmlParser(heading)}</Heading>
            {subHeading && <SubHeading>{subHeading}</SubHeading>}
            <Body>{ReactHtmlParser(body)}</Body>
            <FormTitle>{formHeading}</FormTitle>
            <FormMessage
              active={!!errors?.AGE_GATE}
              content="You must be at least at least 18 years of age (19+ in NE) to register for this event."
              style={{ order: 6 }}
            />
            <Questions>
              {fields &&
                fields
                  .sort((a, b) => a.order - b.order)
                  .map((field, index) => {
                    return (
                      <QuestionContainer
                        fullSize={field.span === "full"}
                        key={index}
                      >
                        {field.type !== "optIn" && !!field.label && (
                          <StyledLabel>{field.label}</StyledLabel>
                        )}
                        <Field
                          field={field}
                          fieldCheck={
                            !!field.requires
                              ? registration[field.requires]
                              : true
                          }
                          value={registration[field.name]}
                          onChange={(value) => handleChange(value, field.name)}
                          error={{
                            status: errors[field.name],
                            message:
                              "This is a required field and cannot be blank.",
                          }}
                          style={{
                            borderRadius:
                              themeContext.borders.inputBorderRadius ?? "4px",
                          }}
                        />
                      </QuestionContainer>
                    );
                  })}
            </Questions>
            <ButtonContainer>
              {submitting ? (
                <RegisterButton disabled>Loading</RegisterButton>
              ) : (
                <RegisterButton onClick={() => handleSubmit()}>
                  {buttonLabel ?? "Register"}
                </RegisterButton>
              )}
            </ButtonContainer>
          </Container>
        </Content>
        <ArtistImage>
          {themeContext.layout.register.overlayImage.active && (
            <OverlayContainer>
              <OverlayImage src={themeContext.images?.logo?.footer} />
            </OverlayContainer>
          )}
        </ArtistImage>
      </ColumnContainer>
    </Layout>
  );
});

const StyledLabel = styled(Label)`
  color: ${(props) => props.theme.typography.register.checkboxLabel.color};
`;

const Logo = styled.img`
  width: 100%;
  max-width: 345px;
  margin-bottom: 24px;
`;

const Content = styled(GridColumn)`
  background-color: ${(props) => props.theme.colors.contentFill};
  padding: ${(props) => props.theme.layout.register.content.padding[0]};
  @media only screen and ${(props) => props.theme.media.medium} {
    padding: ${(props) => props.theme.layout.register.content.padding[1]};
  }
`;

const OverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const OverlayImage = styled.img`
  width: 100%;
  max-width: ${(props) => props.theme.layout.register.overlayImage.width};
  margin-bottom: 15%;
  @media only screen and ${(props) => props.theme.media.small} {
    display: none;
  }
`;

const ArtistImage = styled(GridColumn)`
  align-items: flex-start;
  background-color: ${(props) => props.theme.colors.imageBackgroundFill};
  background-image: url(${(props) => props.theme.images.artistImage.mobile});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: ${(9 / 16) * 100}%;
  position: relative;
  width: ${(props) => props.theme.layout.register.artistImage.width[0]};

  @media only screen and ${(props) => props.theme.media.medium} {
    background-image: url(${(props) => props.theme.images.artistImage.desktop});
    background-size: cover;
    padding-top: ${(props) =>
      (props.theme.layout.register.artistImage.imageHeight /
        props.theme.layout.register.artistImage.imageWidth) *
      props.theme.layout.register.artistImage.width[1].substring(0, 2)}%;
    width: ${(props) => props.theme.layout.register.artistImage.width[1]};
    flex-shrink: 0;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.medium} {
    flex-direction: row;
  }
`;

const Container = styled(GridColumn)`
  width: 100%;
  margin: 0 auto;
  text-align: ${(props) => props.theme.layout.register.content.align[1]};
  max-width: ${(props) => props.theme.layout.register.content.maxWidth[1]};

  @media only screen and ${(props) => props.theme.media.small} {
    max-width: ${(props) => props.theme.layout.register.content.maxWidth[0]};
    text-align: ${(props) => props.theme.layout.register.content.align[0]};
  }
`;

const Heading = styled(H2)`
  color: ${(props) => props.theme.typography.register.heading.color};
  font-size: ${(props) => props.theme.typography.register.heading.fontSize[0]};
  font-weight: ${(props) => props.theme.typography.register.heading.fontWeight};
  line-height: ${(props) =>
    props.theme.typography.register.heading.lineHeight[0]};
  max-width: ${(props) => props.theme.typography.register.heading.maxWidth[0]};
  margin-bottom: ${(props) =>
    props.theme.typography.register.heading.marginBottom[0]};
  order: ${(props) => props.theme.typography.register.heading.order};
  text-transform: ${(props) =>
    props.theme.typography.register.heading.textTransform};
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: ${(props) =>
      (props.theme.typography.register.heading.fontSize[1].substring(0, 2) /
        1239) *
      100}vw;
    line-height: ${(props) =>
      (props.theme.typography.register.heading.lineHeight[1].substring(0, 2) /
        1239) *
      100}vw;
    margin-bottom: ${(props) =>
      (props.theme.typography.register.heading.marginBottom[1].substring(0, 2) /
        1239) *
      100}vw;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: ${(props) =>
      props.theme.typography.register.heading.fontSize[1]};
    line-height: ${(props) =>
      props.theme.typography.register.heading.lineHeight[1]};
    margin-bottom: ${(props) =>
      props.theme.typography.register.heading.marginBottom[1]};
    max-width: ${(props) =>
      props.theme.typography.register.heading.maxWidth[1]};
  }
`;

const SubHeading = styled(H2)`
  color: ${(props) => props.theme.typography.register.subHeading.color};
  font-size: ${(props) =>
    props.theme.typography.register.subHeading.fontSize[0]};
  font-weight: ${(props) =>
    props.theme.typography.register.subHeading.fontWeight};
  line-height: ${(props) =>
    props.theme.typography.register.subHeading.lineHeight[0]};
  margin: ${(props) => props.theme.typography.register.subHeading.margin[0]};
  text-transform: ${(props) =>
    props.theme.typography.register.subHeading.textTransform};
  order: ${(props) => props.theme.typography.register.subHeading.order};
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: ${(props) =>
      (props.theme.typography.register.subHeading.fontSize[1].substring(0, 2) /
        1239) *
      100}vw;
    line-height: ${(props) =>
      (props.theme.typography.register.subHeading.lineHeight[1].substring(
        0,
        2
      ) /
        1239) *
      100}vw;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: ${(props) =>
      props.theme.typography.register.subHeading.fontSize[1]};
    line-height: ${(props) =>
      props.theme.typography.register.subHeading.lineHeight[1]};
    margin: ${(props) => props.theme.typography.register.subHeading.margin[1]};
  }
`;

const Body = styled(P)`
  color: ${(props) => props.theme.typography.register.body.color};
  font-weight: ${(props) => props.theme.typography.register.body.fontWeight};
  ${(props) => props.theme.typography.register.body.font};
  font-size: ${(props) => props.theme.typography.register.body.fontSize[0]};
  line-height: ${(props) => props.theme.typography.register.body.lineHeight[0]};
  margin-bottom: ${(props) =>
    props.theme.typography.register.body.marginBottom[0]};
  order: 3;
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: ${(props) =>
      (props.theme.typography.register.body.fontSize[1].substring(0, 2) /
        1239) *
      100}vw;
    line-height: ${(props) =>
      (props.theme.typography.register.body.lineHeight[1].substring(0, 2) /
        1239) *
      100}vw;
    margin-bottom: ${(props) =>
      (props.theme.typography.register.body.marginBottom[1].substring(0, 2) /
        1239) *
      100}vw;
  }
  @media only screen and ${(props) => props.theme.media.large} {
    font-size: ${(props) => props.theme.typography.register.body.fontSize[1]};
    line-height: ${(props) =>
      props.theme.typography.register.body.lineHeight[1]};
    margin-bottom: ${(props) =>
      props.theme.typography.register.body.marginBottom[1]};
  }
`;

const FormTitle = styled(H3)`
  color: ${(props) => props.theme.typography.register.formHeading.color};
  ${(props) => props.theme.typography.register.formHeading.font};
  font-size: ${(props) =>
    props.theme.typography.register.formHeading.fontSize[1]};
  line-height: ${(props) =>
    props.theme.typography.register.formHeading.fontSize[1]};
  font-weight: ${(props) =>
    props.theme.typography.register.formHeading.fontWeight};
  margin-top: 0;
  margin-bottom: ${(props) =>
    props.theme.typography.register.formHeading.marginBottom[1]};
  order: 4;
  text-transform: ${(props) =>
    props.theme.typography.register.formHeading.textTransform};
  /* @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 2.018vw;
    line-height: 2.583vw;
  } */
  @media only screen and ${(props) => props.theme.media.small} {
    font-size: ${(props) =>
      props.theme.typography.register.formHeading.fontSize[0]};
    line-height: ${(props) =>
      props.theme.typography.register.formHeading.fontSize[0]};
    margin-bottom: ${(props) =>
      props.theme.typography.register.formHeading.marginBottom[0]};
  }
`;

const Questions = styled(GridRow)`
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: -${(props) => props.theme.typography.register.input.horizontalSpacing[0] / 2}px;
  margin-right: -${(props) => props.theme.typography.register.input.horizontalSpacing[0] / 2}px;
  order: 5;
  @media only screen and ${(props) => props.theme.media.large} {
    margin-left: -${(props) => props.theme.typography.register.input.horizontalSpacing[1] / 2}px;
    margin-right: -${(props) => props.theme.typography.register.input.horizontalSpacing[1] / 2}px;
  }
`;

const QuestionContainer = styled(GridColumn)`
  flex: 0 1 ${(props) => (props.fullSize ? "100%" : "50%")};
  padding: ${(props) =>
      props.theme.typography.register.input.verticalSpacing[0]}px
    ${(props) =>
      props.theme.typography.register.input.horizontalSpacing[0] / 2}px
    0
    ${(props) =>
      props.theme.typography.register.input.horizontalSpacing[0] / 2}px;
  font-weight: ${(props) => props.theme.typography.register.input.fontWeight};

  @media only screen and ${(props) => props.theme.media.large} {
    padding: ${(props) =>
        props.theme.typography.register.input.verticalSpacing[1]}px
      ${(props) =>
        props.theme.typography.register.input.horizontalSpacing[1] / 2}px
      0
      ${(props) =>
        props.theme.typography.register.input.horizontalSpacing[1] / 2}px;
  }
`;

const ButtonContainer = styled(GridColumn)`
  margin: ${(props) => props.theme.typography.register.button.margin[0]};
  order: 6;
  width: 100%;

  @media only screen and ${(props) => props.theme.media.large} {
    margin: ${(props) => props.theme.typography.register.button.margin[1]};
  }
`;

export const RegisterButton = styled(ButtonPrimary)`
  align-items: center;
  background-color: ${(props) => props.theme.typography.register.button.fill};
  border: ${(props) => props.theme.typography.register.button.border};
  border-radius: ${(props) =>
    props.theme.typography.register.button.borderRadius};
  box-shadow: none;
  color: ${(props) => props.theme.typography.register.button.color};
  ${(props) => props.theme.fonts.button};
  font-size: ${(props) => props.theme.typography.register.button.fontSize[0]};
  font-weight: ${(props) => props.theme.typography.register.button.fontWeight};
  line-height: ${(props) =>
    props.theme.typography.register.button.lineHeight[0]};
  height: ${(props) => props.theme.typography.register.button.height[0]};
  transition: all ease .3s;
  text-transform: ${(props) =>
    props.theme.typography.register.button.textTransform};
  /* @media only screen and ${(props) => props.theme.media.medium} {
    font-size: 1.786vw;
    height: 6vw;
  } */
  @media only screen and ${(props) => props.theme.media.medium} {
    font-size: ${(props) => props.theme.typography.register.button.fontSize[1]};
    line-height: ${(props) =>
      props.theme.typography.register.button.lineHeight[1]};
    height: ${(props) => props.theme.typography.register.button.height[1]};
  }

  &:hover {
    background-color: ${(props) =>
      props.theme.typography.register.button.fillHover};
    box-shadow: none;
    color: ${(props) => props.theme.typography.register.button.colorHover};
    border: ${(props) => props.theme.typography.register.button.borderHover};
  }
`;
