import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { MessageProvider, ThemeWrapper } from "Components";
import { Root } from "./Root";
import { Reminder } from "./Reminder";

export const Routes = () => {
  return (
    <ThemeWrapper>
      <MessageProvider>
        <Router>
          <Switch>
            <Route exact path="/reminder" component={Reminder} />
            <Route path="/" component={Root} />
            <Redirect to="/" />
          </Switch>
        </Router>
      </MessageProvider>
    </ThemeWrapper>
  );
};
