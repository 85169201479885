import React, { useContext } from "react";
import ReactHtmlParser from "react-html-parser";
import { Layout, BodySection } from "../Layout";
import styled, { ThemeContext } from "styled-components";
import { GridColumn, H1, H4 } from "notes";

export const PostShow = ({ event = {} }) => {
  const { heading, subHeading, body, thankYouHeader, thankYouBody } = event;
  const themeContext = useContext(ThemeContext);
  return (
    <Layout
      headerStyle={{ borderBottom: "1px solid #707070" }}
      videoContent={
        <ThankYou>
          <H1>{thankYouHeader}</H1>
          <H4>{thankYouBody}</H4>
          {themeContext.images.postShow?.overlayImage && (
            <OverlayImage src={themeContext.images.postShow.overlayImage} />
          )}
        </ThankYou>
      }
      heading={heading}
      subHeading={subHeading}
      videoPadding={themeContext.layout.postShow?.videoPadding}
    >
      {body ? <BodySection>{ReactHtmlParser(body)}</BodySection> : undefined}
    </Layout>
  );
};

const OverlayImage = styled.img`
  margin-top: ${(props) =>
    props.theme.layout.postShow.overlayImage.marginTop[1]};
  width: ${(props) => props.theme.layout.postShow.overlayImage.width[1]};

  @media only screen and ${(props) => props.theme.media.small} {
    margin-top: ${(props) =>
      props.theme.layout.postShow.overlayImage.marginTop[0]};
    width: ${(props) => props.theme.layout.postShow.overlayImage.width[0]};
  }
`;

const ThankYou = styled(GridColumn)`
  align-items: center;
  background-color: ${(props) =>
    props.theme.layout.postShow?.videoFill ?? "transparent"};
  justify-content: center;
  width: 100%;
  ${H1} {
    color: ${(props) => props.theme.typography.postShow.overlayHeading.color};
    font-size: ${(props) =>
      props.theme.typography.postShow.overlayHeading.fontSize[1]};
    font-weight: ${(props) =>
      props.theme.typography.postShow.overlayHeading.fontWeight};
    line-height: ${(props) =>
      props.theme.typography.postShow.overlayHeading.lineHeight[1]};
    text-align: center;
    margin-bottom: ${(props) =>
      props.theme.typography.postShow.overlayHeading.marginBottom[1]};
    text-transform: ${(props) =>
      props.theme.typography.postShow.overlayHeading.textTransform};
  }
  ${H4} {
    color: ${(props) => props.theme.typography.postShow.overlayBody.color};
    font-size: ${(props) =>
      props.theme.typography.postShow.overlayBody.fontSize[1]};
    font-weight: ${(props) =>
      props.theme.typography.postShow.overlayBody.fontWeight};
    line-height: ${(props) =>
      props.theme.typography.postShow.overlayBody.lineHeight[1]};
    margin: 0 24px;
    text-align: center;
  }
  @media only screen and ${(props) => props.theme.media.small} {
    ${H1} {
      font-size: ${(props) =>
        props.theme.typography.postShow.overlayHeading.fontSize[0]};
      line-height: ${(props) =>
        props.theme.typography.postShow.overlayHeading.lineHeight[0]};
      margin-bottom: ${(props) =>
        props.theme.typography.postShow.overlayHeading.marginBottom[0]};
    }
    ${H4} {
      font-size: ${(props) =>
        props.theme.typography.postShow.overlayBody.fontSize[0]};
      line-height: ${(props) =>
        props.theme.typography.postShow.overlayBody.lineHeight[0]};
    }
  }
`;
