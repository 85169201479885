import React, { useContext } from "react";
import { MAXTheme } from "notes";
import { ThemeProvider } from "styled-components";
import { EventContext } from "Components";
import { theme } from "theme";
import logoHeader from "Images/logoHeader.png";
import logoFooter from "Images/logoFooter.png";

export const ThemeWrapper = ({ children }) => {
  const { event } = useContext(EventContext);

  const updatedTheme = {
    ...MAXTheme,
    ...theme,
    images: {
      artistImage: {
        desktop: `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}/o/${event.register?.artistImageDesktop?.path}?alt=media`,
        mobile: `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}/o/${event.register?.artistImageMobile?.path}?alt=media`,
      },
      logo: {
        header: logoHeader,
        footer: logoFooter,
      },
      // postShow: {
      //   overlayImage: overlayImage,
      // },
    },
  };

  return <ThemeProvider theme={updatedTheme}>{children}</ThemeProvider>;
};
