import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { Video, Heartbeater } from "Components";
import { BodySection, Layout } from "../Layout";
import { GridColumn, GridRow } from "notes";

export const Show = ({ event = {} }) => {
  const [height, setHeight] = useState(0);
  const { heading, subHeading, body, videoId, chatId } = event;

  const ref = useRef(null);

  useEffect(() => {
    if (ref) {
      setHeight(window.innerHeight - (ref.current.clientHeight + 79));
    }
  }, [ref]);

  return (
    <>
      <Layout
        videoContent={
          <Container>
            <VideoContainer ref={ref}>
              <Video id={videoId} />
            </VideoContainer>
            <ChatWindow
              src={`https://vimeo.com/event/${chatId}/chat/`}
              height="100%"
              frameborder="0"
              calcHeight={height}
            />
          </Container>
        }
        heading={heading}
        subHeading={subHeading}
        videoPadding={0}
        videoStyle={{ position: "relative", width: "100%" }}
      >
        {body ? <BodySection>{ReactHtmlParser(body)}</BodySection> : undefined}
      </Layout>
      <Heartbeater />
    </>
  );
};

const Container = styled(GridColumn)`
  position: relative;
  width: 100%;
  @media only screen and ${(props) => props.theme.media.medium} {
    flex-direction: row;
  }
`;

const VideoContainer = styled(GridRow).attrs({ as: "div" })`
  display: flex;
  width: 100%;
`;

const ChatWindow = styled.iframe`
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: ${(props) => props.calcHeight}px;
  @media only screen and ${(props) => props.theme.media.medium} {
    flex-direction: row;
    width: 392px;
    height: auto;
  }
`;
