import React from "react";
import styled from "styled-components";
import { GridColumn, Icon } from "notes";
import Slider from "react-slick";

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context("Images/retailer-logos", false, /\.(png|jpe?g|svg)$/)
);

const IconPrevious = styled(Icon)`
  width: 60px;
  height: 60px;
  left: -45px;
  path {
    fill: #757575;
  }
`;

const IconNext = styled(Icon)`
  width: 60px;
  height: 60px;
  right: -45px;
  path {
    fill: #757575;
  }
`;

export const Carousel = () => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 10000,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    nextArrow: <IconNext name="Next" />,
    prevArrow: <IconPrevious name="Previous" />,
    responsive: [
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <CarouselContainer xCenter>
      <SliderContainer>
        <Slider {...settings}>
          {!!images &&
            images.sort().map((item, index) => (
              <Slide key={index}>
                <Inner>
                  <BrandImage src={item} />
                </Inner>
              </Slide>
            ))}
        </Slider>
      </SliderContainer>
    </CarouselContainer>
  );
};

const BrandImage = styled.img`
  height: auto;
  width: 100%;
`;

const SliderContainer = styled(GridColumn)`
  padding: 0 60px;
  width: 100%;
`;

const Slide = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 0 11px;
  min-width: 0;
  padding-top: 90%;
  position: relative;
`;

const Inner = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.carouselItemFill};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 8px;
  position: absolute;
  top: 0%;
  right: 11px;
  bottom: 0;
  left: 11px;
`;

const CarouselContainer = styled(GridColumn)`
  background-color: ${(props) => props.theme.colors.carouselFill};
  padding: 40px 0;
  width: 100%;
`;
